<template>
	<div class="menu-header">
		<el-row>
			<el-col :span="8">
				<el-link type="primary" :underline="false" @click="toIndexPage">
					<span class="logo">
						{{ domainInfo ? domainInfo.schemeName : "我是记分长" }}
					</span>
				</el-link>
				<span class="logo-tips">文化艺术体育竞技展演报名系统</span>
			</el-col>
			<el-col :span="15" style="text-align: -webkit-right;">
				<el-menu :default-active="$route.path" mode="horizontal" background-color="#1E4683" text-color="#fff"
					@select="handleMenusSelect" :router="true">
					<el-menu-item index="/joinmatchinfo" :route="{path:'/joinmatchinfo'}">比赛详情</el-menu-item>
					<el-menu-item index="/joinmatchrealnameauth" :route="{path:'/joinmatchrealnameauth'}">实名认证
					</el-menu-item>
					<el-menu-item index="/joinmatchonlinesignup" :route="{path:'/joinmatchonlinesignup'}">
						在线报名</el-menu-item>
					<el-menu-item index="/joinmatchformsignuptable" :route="{path:'/joinmatchformsignuptable'}">正式报名表
					</el-menu-item>
					<el-menu-item index="/joinmatcharrangtable" :route="{path:'/joinmatcharrangtable'}">赛事安排表
					</el-menu-item>
					<el-menu-item index="/joinmatchsummarytable" :route="{path:'/joinmatchsummarytable'}">赛程总表
					</el-menu-item>
					<el-menu-item index="/joinmatchmymatchtabe" :route="{path:'/joinmatchmymatchtabe'}">选手赛程表
					</el-menu-item>
					<el-menu-item index="/joinmatchscoretable" :route="{path:'/joinmatchscoretable'}">成绩表</el-menu-item>
					<el-menu-item index="/joinmatchgroupsetitem" :route="{path:'/joinmatchgroupsetitem'}">组别设项
					</el-menu-item>
				</el-menu>

			</el-col>
			<el-col :span="menusShow?1:19" style="text-align: center;">
				<el-dropdown @command="headderCommand">
					<img class="settingImg ant-dropdown-trigger"
						data-src="static/set_icon.png"
						src="static/set_icon.png" lazy="loaded">
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
							<el-dropdown-item command="out">退出</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</el-col>
		</el-row>
		<modifypassword ref="modifypassword"> </modifypassword>
	</div>
</template>

<script>
	import modifypassword from '@index/components/modifypassword.vue';
	import common from '@index/utils/common';
	import {
		Message
	} from "element-ui";
	import {
		handleQueryUserPublicMatchAuth
	} from '@index/api/team/teamindex'
	export default {
		name: 'header-matchs',
		components: {
			modifypassword
		},
		data() {
			return {
				menusShow: false,
				domainInfo: null,
			};
		},
		watch: {
			$route: {
				handler: function() {

				},
				immediate: true
			}
		},
		created() {
			// window.addEventListener('storage', this.afterQRScan)
		},
		mounted() {
			this.handleDomain();
		},
		destroyed() {
			// window.removeEventListener('storage', this.afterQRScan)
		},
		methods: {
			headderCommand(command) {
				switch (command) {
					case "modifyPassword":
						this.$refs.modifypassword.openDialog();
						break;
					case "out":
						common.signout();
						break;
				}
			},
			toIndexPage() {
				handleQueryUserPublicMatchAuth().then(res => {
					if (res.status == 200) {
						if (res.data.authStatus == '1' || res.data.authStatus == '2') { //允许发布赛事（授权比赛）
							this.$router.push({
								path: '/transitionpage'
							})
						} else { //不允许发布赛事
							this.$router.push({
								path: '/team'
							})
						}

					} else {
						Message({
							type: "error",
							message: res.msg,
							duration: 5000
						});
					}
				});
			},
			init(menusShow) {
				this.$data.menusShow = menusShow;
			},
			handleMenusSelect() {
				// ...
			},
			// 处理头部函数
			handleDomain() {
				const domainInfo = sessionStorage.getItem('domainInfo');
				if (domainInfo) {
					this.domainInfo = JSON.parse(domainInfo);
				}
			}

		}
	}
</script>
<style>
	.menu-header {
		background: rgb(30, 70, 131);
		height: 3.5rem;
		line-height: 3.5rem;
		display: inline-block;
		width: 100%;

	}

	.el-menu.el-menu--horizontal {
		border-bottom: none;
		float: right;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: none !important;
		color: white !important;
		background: #F32C6C !important;
	}

	.el-menu--horizontal>.el-menu-item {
		height: 3.5rem;
		line-height: 3.5rem;
	}

	.el-dropdown {

		-o-object-fit: fill;
		object-fit: fill;

		cursor: pointer;
		margin-top: 14px;
	}

	.settingImg {
		width: 22px;
		height: 22px;
		-o-object-fit: fill;
		object-fit: fill;
		float: right;
	}



	.logo-tips {
		padding-left: 10px;
		font-size: 0.7rem !important;
		color: #D7D7D7;
	}

	.el-menu-item {
		font-size: 0.9rem !important;


	}

	.el-menu-item:hover {
		background-color: #F32C6C !important;
	}
</style>
<style lang="scss" scoped>
	.logo {
		font-size: 1.6rem !important;
		color: white;

		padding-left: 1.5rem;
		font-weight: bolder;
	}
</style>