<!--参赛队进入比赛（代表队进入比赛）-->
<template>
  <div class="page-content">
    <commonHeader ref="commonHeader"></commonHeader>
    <router-view/>
  </div>
</template>

<script>
  import commonHeader from './components/header';
  export default {
    name: 'mymatchs',
    components: {
      commonHeader
    },
    data() {
      return {
        menusShow: true,
        currentMenuIndex: "1",
      };
    },
    watch: {

    },
    created() {
     

    },
    mounted() {
 this.$refs.commonHeader.init(true);
    },
    destroyed() {

    },
    methods: {


    }
  }
</script>

<style lang="scss">
  // .page-content {
  //   overflow-y: hidden;
  //   height: calc(100vh - 0px);
  // }
</style>
